import React from "react";
import Hero from "../componants/hero/hero";
import About from "../componants/aboutMe/about";
import Skills from "../componants/skills/skills";
import Works from "../componants/works/works";
import Blogs from "../componants/blogs/blogs";

const Homepage = () => {
  return (
    <>
      <Hero />
      <About />
      <Skills />
      <Works />
      <Blogs />
    </>
  );
};

export default Homepage;
