import React from "react";
import "./works.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

// images ===================================>
import project1 from "../../assets/project1.webp";
import project2 from "../../assets/project2.webp";
import project3 from "../../assets/project3.webp";
import project4 from "../../assets/project4.webp";
import project5 from "../../assets/project5.webp";

const Works = () => {
  const images = [
    {
      img: project1,
    },
    {
      img: project2,
    },
    {
      img: project3,
    },
    {
      img: project4,
    },
    {
      img: project5,
    },
  ];
  // const breakpoints = {
  //   // when window width is >= 320px
  //   767: {
  //     navigation: true,
  //   },
  //   // when window width is >= 480px
  //   300: {
  //     navigation: false,
  //   },
  // };
  return (
    <div className="works">
      <div className="container">
        <div className="main--title">
          <h1 className="H1-U">Works</h1>
          <p className="Para-M">
            I had the pleasure of working with these awesome projects
          </p>
        </div>
      </div>
      <div className="slides">
        <Swiper
          // breakpoints={breakpoints}
          spaceBetween={10}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
        >
          {images.map((data) => {
            return (
              <div className="slide">
                <SwiperSlide>
                  <img src={data.img} alt="" />
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Works;
