import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaGithub, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className=" flex-wrap">
          <Col lg={3} md={6} sm={12} className=" mb-3">
            <div className="copy-right">
              © 2024 <a href="">@ahmed_dev13</a>. All rights reserved.
            </div>
          </Col>
          <Col xl={3} lg={3} md={6} sm={12} className="mb-3">
            <span>Privacy Policy</span>
            <span>Terms&Conditions</span>
          </Col>
          <Col lg={3} md={6} sm={12} className="d-flex aligm-items-center ">
            <FaInstagram />
            <FaYoutube />
            <FaGithub />
          </Col>
          <Col lg={2} md={6} sm={12} className=" mb-3">
            Design By <a href="">JohannLeon</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
