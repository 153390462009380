import React from "react";
import "./App.css";
import NavBar from "./componants/navBar/navBar";
import Footer from "./componants/footer/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
import Contactpage from "./pages/contactpage";

function App() {
  // useEffect(() => {
  //   function spark(e) {
  //     const i = document.createElement("i");
  //     i.style.left = e.pageX + "px";
  //     i.style.top = e.pageY + "px";
  //     i.style.scale = `${Math.random() * 1.5 + 0.5}`;
  //     i.style.setProperty("--x", getRandomTransitionValue());
  //     i.style.setProperty("--y", getRandomTransitionValue());
  //     document.body.appendChild(i);
  //     setTimeout(() => {
  //       document.body.removeChild(i);
  //     }, 500);
  //   }

  //   function getRandomTransitionValue() {
  //     return `${Math.random() * 300 - 50}px`;
  //   }

  //   const appDiv = appRef.current;
  //   appDiv.addEventListener("mousemove", spark);

  //   return () => {
  //     appDiv.removeEventListener("mousemove", spark);
  //   };
  // }, []);

  return (
    <div
      // ref={appRef}
      className="App"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <NavBar />
      <div style={{ flex: 1 }}>
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/contact" element={<Contactpage />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
