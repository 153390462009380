import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Contact = () => {
  return (
    <section className="contact ">
      <Container>
        <div className="main--title">
          <h1 className="H1-U">Contact</h1>
          <p className="Para-M">
            My thoughts on technology and business, welcome to subscribe
          </p>
        </div>
        <div className=" w-fit m-auto">
          <button className="Logo-M">Send me a message</button>
        </div>
        <div className="form">
          <Row className="d-flex align-items-center my-3">
            <Col md={6} sm={12} className="d-flex flex-column mb-3">
              <label className="mb-2">Your name *</label>
              <input type="text" placeholder="Enter your name" name="" id="" />
            </Col>
            <Col md={6} sm={12} className="d-flex flex-column mb-3">
              <label className="mb-2">Your email *</label>
              <input
                type="email"
                placeholder="Enter your email"
                name=""
                id=""
              />
            </Col>
          </Row>
          <div className=" my-3">
            <label className="mb-2">Your message *</label>
            <input type="text" placeholder="Enter your needs" name="" id="" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
