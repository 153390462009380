import React from "react";
import "./navBar.css";

import { IoCodeWorking } from "react-icons/io5";
import { FaInstagram, FaGithub } from "react-icons/fa";
import { RiDiscordLine } from "react-icons/ri";

const NavBar = () => {
  return (
    <nav className="container space-between py-11">
      <div className="brand d-flex align-items-center">
        <IoCodeWorking className="logo flex-align me-3" />
        <h2>Ahmed_dev13</h2>
      </div>
      <ul className="space-between Menu-M mobile-hide">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/contact">contact</a>
        </li>
        <div className="social--media mobile-hide space-between Media-M">
          <li className="d-flexend">
            <FaInstagram className="icon" />
            <a
              href="https://www.instagram.com/ahmed_dev13"
              className="tab-hide"
            >
              {" "}
              instagram
            </a>
          </li>
          <li className="d-flexend ">
            <RiDiscordLine className="icon" />
            <a href="" className="tab-hide">
              {" "}
              discord
            </a>
          </li>
          <li className="d-flexend">
            <FaGithub className="icon" />
            <a href="" className="tab-hide">
              {" "}
              github
            </a>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default NavBar;
