import React from "react";
import "./skills.css";

import { TfiPaintRoller } from "react-icons/tfi";
import { FaLaptopCode } from "react-icons/fa";

import { RiHtml5Line } from "react-icons/ri";
import { FaReact, FaCss3Alt } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";

const Skills = () => {
  return (
    <div className="skills">
      <div className="container">
        <div className="main--title">
          <h1 className="H1-U">Skills</h1>
          <p className="Para-M">
            I am striving to never stop learning and improving
          </p>
        </div>
        <div className="tech--list space-around ">
          <div className="box">
            <FaLaptopCode />
            <div className="Menu-M">Front End</div>
            <p className="Para-M">HTML-CSS-JS-REACTJS</p>
          </div>
          <div className="box">
            <TfiPaintRoller />
            <div className="Menu-M">Back End</div>
            <p className="Para-M">Nodejs-EXPRESS-MONGODB</p>
          </div>
        </div>
        <div className="skills--detail">
          <div className="html">
            <div className="icon">
              <RiHtml5Line />
            </div>
            <h2 className="H2-M">HTML</h2>
          </div>
          <div className="css">
            <div className="icon">
              <FaCss3Alt />
            </div>
            <h2 className="H2-M">CSS</h2>
          </div>
          <div className="js">
            <div className="icon">
              <FaNodeJs />
            </div>
            <h2 className="H2-M">JS</h2>
          </div>
          <div className="react">
            <div className="icon">
              <FaReact />
            </div>
            <h2 className="H2-M">REACT</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
