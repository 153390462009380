import React from "react";
import "./blogs.css";
import { Container, Row, Col } from "react-bootstrap";
import blog from "../../assets/blog.png";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Blogs = () => {
  return (
    <Container>
      <div className="main--title">
        <h1 className="H1-U">Blogs</h1>
        <p className="Para-M">
          My thoughts on technology and business, welcome to subscribe
        </p>
      </div>
      <div className="blogs">
        <div className="image">
          <img src="" alt="" />
        </div>
        <Row className="align-items-center pb-10">
          <Col sm={12} md={5} className="image">
            <img src={blog} alt="" className=" m-sm-auto" />
          </Col>
          <Col sm={12} md={7} className="content ">
            <h2 className="text-primary H2-U">
              What does it take to become a web developer?
            </h2>
            <p className="Para-U ">
              Web development, also known as website development, encompasses a
              variety of tasks and processes involved in creating websites for
              the internet…
            </p>
            <div className="d-flex align-items-center text-primary">
              <a
                href="readmore"
                className="Para-U fs-5 text-primary border-b-2"
              >
                Read More{" "}
              </a>
              <MdOutlineKeyboardDoubleArrowRight />
            </div>
            <div className="content-details d-flex align-items-center">
              <div className="type me-4 px-3 py-1 bg-gray-600 rounded-2xl">
                web development
                <span></span>
              </div>
              <div className="date m-4 font-bold">
                Date : <span className="font-normal">10.Oct 2023</span>
              </div>
              <div className="read-count m-4 font-bold">
                read : <span className="font-normal">1 Min</span>
              </div>
            </div>
          </Col>
        </Row>
        <div className="border-t-4 border-gray-600 py-16 text-center ">
          <button className="rounded-3xl text-dark bg-primary p-3 me-3">
            Read More
          </button>
          <button className="rounded-3xl border-2 border-primary p-3">
            Subscribe
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Blogs;
