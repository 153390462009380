import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { MdOutlinePlace, MdWorkOutline } from "react-icons/md";
import profile from "../../assets/profile.png";
import "./hero.css";

import { Container, Row, Col, Spinner } from "react-bootstrap";

// import bgVideo from "../../assets/hero-bg.mp4";
import bgVideo from "../../assets/bg.mp4";

const Hero = () => {
  return (
    <>
      <div className="hero">
        <div className="video-background">
          <video style={{ opacity: ".5" }} autoPlay muted loop id="myVideo">
            <source src={bgVideo} type="video/mp4" />
          </video>
        </div>
        <Container>
          <h1 className="title">developer</h1>

          <Row className="holder align-items-center">
            <Col lg={4} md={12}>
              <div className="card">
                <img src={profile} alt="profile" />
                <div className="personal--info">
                  <h3 className="name">Ahmed</h3>
                  <div className="work">full-stack developer</div>
                </div>
                <div className="info">
                  <div className="gmail flex-align">
                    <FaRegEnvelope />
                    ahmedzeintsu@gmail.com
                  </div>
                  <div className="place flex-align">
                    <MdOutlinePlace />
                    Morocco
                  </div>
                  <div className="work flex-align">
                    <MdWorkOutline />
                    Freelance
                  </div>
                  <div className="web-site flex-align">
                    <FaLink />
                    www.ahmedaddy.dev
                  </div>
                </div>
                <div className="skills flex-align">
                  <div className="javascript">javascript</div>
                  <div className="react">react</div>
                  <div className="nodejs-express">nodejs/express</div>
                  <div className="mongodb">mongodb</div>
                </div>
                <button className="button CV">Download CV</button>
              </div>
            </Col>
            <Col lg={6} md={8}>
              <div className="info">
                <div className="content">
                  <div className="tags">&lt; h1 &gt;</div>
                  <div>hey</div>
                  <br />
                  <div>
                    Iam <span>Ahmed</span>{" "}
                  </div>
                  <br />
                  <h1 className="H1-U">Full-Stack Developer </h1>
                  <div className="tags">&lt; /h1 &gt;</div>
                  <br />
                  <div className="tags">&lt; p &gt;</div>
                  <p>
                    I help business grow by crafting amazing web experiences. If
                    you’re looking for a developer that likes to get stuff done,
                  </p>
                  <div className="tags">&lt; /p &gt;</div>
                  <div className="lets-talk flex-align">
                    Let's Talk <FaRegEnvelope />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={2} md={4} className="p-0">
              <div className="stats">
                <div className="stat Para-M flex-align">
                  4 <div>Programming Language</div>
                </div>
                <div className="stat Para-M flex-align">
                  6 <div>Development Tools</div>
                </div>
                <div className="stat Para-M flex-align">
                  8 <div>Years Of Experience</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
